html,
body,
#root {
    background-color: rgb(212, 215, 216);
    /*background-color: #f50057;*/
    /*background-color: #0f0f38;*/
    /*background-color: #ff0000;*/;
    /*min-height: 100vh;*/
    /*display: table;*/
    min-width: 100%;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    height: 100%;
    min-height: 100% !important;
    overflow-x:hidden;
    color: #312b2b;
    font-size: 16px;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    z-index: -2;
}

.bg-img {
    position: fixed;
    height: auto;
    z-index: -1;
}

h1 {
    font-family: 'Urbanist', sans-serif;
    font-weight: 300;
}

#root {
    overflow: auto;
}

.code {
    flex: 1;
}

.code-readonly > div {
    height: auto;
}

hr {
    border: 1px solid gray;
    opacity: 0.2;
}

.code > div {
    border-radius: 5px;
    box-shadow: 0px 1px 10px gray;
    padding: 0.5em 0 0.5em;
}

.all-center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* establish flex container */
    flex-direction: column;
    /* make main axis vertical */
    justify-content: center;
    /* center items vertically, in this case */
    align-items: center;
    /* center items horizontally, in this case */
    height: 100%;
}

.text {
    white-space: pre-line;
}

.tooltip {
    white-space: pre-line;
    opacity: 1 !important;
    font-size: 1em !important;
    max-width: 300px;
}

.highlight {
    transform: translate(0, 0);
    position: relative;
    color: white; 
    background-color: black; 
    border: 1px;
  }